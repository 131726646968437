<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">{{ $t('customer_information.information_content.detail_title') }} <br /></h2>
        <div class="d-flex">
          <b-button variant="primary" class="d-flex align-items-center mr-1"
            @click="$router.push({ name: 'contenu-informationnel-edit', params: { id: form.id } })">
            <i class="las la-edit mr-25 la-lg"></i>
            <!-- Nouvelle fiche contact -->
            {{ $t('button.edit') }}
          </b-button> 
                 <b-button variant="outline-primary" @click="$router.push({ name: 'contenu-informationnel-list' })"
          class="d-flex align-items-center">
          <i class="las la-arrow-left mr-50"></i>
          <span>{{ $t('button.back_to_list') }}</span>
        </b-button>
        </div>

      </div>
    </b-card>
    <b-card v-if="form == null">
      <div class="text-center my-2">
        <b-spinner class="align-middle" />
      </div>
    </b-card>

    <div class="blog-detail-wrapper" v-else>
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card v-if="isCreatingProduct == true">
            <b-col>
              <b-skeleton-img no-aspect animation="fade" height="200px"></b-skeleton-img>
            </b-col>
            <b-col cols="12" class="pt-2">
              <b-skeleton animation="fade" width="75%"></b-skeleton>

            </b-col>
            <b-col cols="12" class="pt-2">
              <b-skeleton animation="fade" width="7%"></b-skeleton>
            </b-col>
            <b-col cols="12" class="pt-2">
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
            </b-col>
            <hr class="my-2">
            <b-col cols="12" class="pt-2 d-flex justify-content-between align-items-center">
              <b-skeleton animation="fade" width="30%" height="50px"></b-skeleton>
              <b-skeleton animation="fade" width="30%" height="50px"></b-skeleton>
              <b-skeleton animation="fade" width="30%" height="50px"></b-skeleton>
            </b-col>
          </b-card>
          <b-card v-else>
       
            <div class="blog-cover-box" v-if="form && form.cover != null">
              <img :src="form.cover" alt="musee" class="blog-cover-img" />
            </div>
            <div>
              <h4 class="my-1">{{ form.title }}</h4>
            </div>
            <div class="my-1 py-25">
              <b-link v-for="(tag, tagIndex) in form.tags" :key="tagIndex">
                <b-badge pill class="mr-75" variant="light-primary">
                  #{{ tag }}
                </b-badge>
              </b-link>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div class="blog-content" v-html="form.description" />
            <hr class="my-2">
            <div>
              <b-row class="px-1">
                <b-col cols="12" class="pb-1">
                  <strong>{{ $t('customer_information.information_content.detail_attached_files') }}</strong> <br />
                </b-col>
              </b-row>
              <DownloadFile :documents="form.attached_files" />
            </div>
          </b-card>
        </b-col>

      </b-row>

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BSpinner,
  BBadge,
  BCardHeader,
  VBTooltip,
  BLink, BSkeleton, BSkeletonImg
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueEditor } from "vue2-editor";

import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import PostsStoreModule from "@/store/blog";
import DownloadFile from "@/components/DownloadFile.vue";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    VueEditor,
    vSelect,
    BSpinner,
    BBadge,
    BCardHeader,
    BLink, BSkeleton, BSkeletonImg,
    DownloadFile,
  },
  setup() {
    const requiredStoreModules = [{ path: "posts", module: PostsStoreModule }];
    // Register module
    registerStoreModule(requiredStoreModules);
    return {
      requiredStoreModules,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    //    "b-modal": VBModal,
    //Ripple,
  },
  data() {
    return {
      currentId: null,
      form: {
        title: null,
        post_category_id: null,
        description: null,
        tags: null,
        attached_files: null,
        cover: null,
        status: null,
      },
      isCreatingProduct: false,
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.loadPost();
      },
    },
  },

  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules)
  },
  mounted() { },
  methods: {
    ...mapActions("posts", {
      action_getPosts: "getPosts",
    }),
    // -------------------------------
    loadPost() {
      this.isCreatingProduct = true;
      this.action_getPosts(this.$route.params.id)
        .then((response) => {
          this.isCreatingProduct = false;
          this.form = response.data.data;
          console.log("this.form: ", this.form);
        })
        .catch((error) => {
          console.log("error::: ", error);
          this.isCreatingProduct = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Une erreur est survenue",
              icon: "CoffeeIcon",
              variant: "danger",
              text: "",
            },
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          >.col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}

img {
  width: 100%;
  aspect-ratio: 16/6;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}



#descr {
  width: 100% !important;
  overflow: hidden;
  margin: 0 auto !important;
}

#descr img {
  width: 50% !important;
  background-size: cover !important;
  background-position: center center !important;
  aspect-ratio: 16/9 !important;
  border-radius: 5px !important;
}

.blog-cover-box {
  width: 100%;
  aspect-ratio: 16/6;
  overflow: hidden;
}

.blog-cover-img {
  width: 100%;

  object-fit: cover;
  transition: 0.5s ease;

}

.blog-cover-img:hover {
  transform: scale(1.01) !important;
  border-radius: 5px;

}
</style>
